.job-offer {
  span {
    font-family: $font-text !important;
  }

  h3 i {
    font-size: 26px;
  }

  p {
    font-size: 1.2rem;
  }

  h4 {
    margin-top: 1.5rem;
  }

  .main-content {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;

    p {
      margin-bottom: 0;
    }

    .job-description {
      img {
        max-width: 100%;
      }
    }
  }

  .share-button {
    margin-right: -1.5rem;
    margin-left: auto;
    margin-bottom: -1rem;
    display: block;
    width: 120px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: border-bottom-left-radius .4s;

    &.collapsed {
      border-bottom-left-radius: 0;

    }
  }
}

#jobs-list {
  .filters {
    border-radius: 50px;
  }
}

.job-item {
  h3 {
    font-size: 28px;
  }

  p {
    font-size: 22px;
    display: flex;
    margin-bottom: 0;
  }

  span {
    font-family: $font-text !important;
    font-size: 17px;
  }


  .job-button-container {
    display: flex;
  }

  .job-cycle-pill {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    //cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 8px;
    color: white;

    /*
    - Préscolaire : #37B446
    - Primaire : #FF4C4C
    - Secondaire : #FC7524
    - Supérieur : #2B6499
    - Autre : #FDBC22
     */
    &.job-cycle-prescolaire {
      background-color: #37B446;
    }

    &.job-cycle-primaire {
      background-color: #FF4C4C;
    }

    &.job-cycle-secondaire {
      background-color: #FC7524;
    }

    &.job-cycle-superieur {
      background-color: #2B6499;
    }

    &.job-cycle-autre {
      background-color: #792094;
    }
  }

  &-buttons {
    flex-grow: 1;
    text-align: right;

    a {
      font-family: $titlefont;

      &.btn:hover {
        color: white;
      }
    }
  }


  hr {
    clear: right;
  }
}

span.job-time {
  font-size: 14px;
  line-height: 1.5;
  padding-left: 5px;
  padding-top: 9px;
}