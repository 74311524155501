.top-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  height: 90px;

  //background-color: #efd8aa;
  //background-image: url("/vendors/images/pubs/EBE-fond.jpg");
  background-position-x: center;
  text-align: center;

  img, video {
    max-height: 90px;
  }

  img.logo, video.logo {
    max-height: 80px;
  }

  @include media-breakpoint-down(xl) {
    background-size: cover;
    background-repeat-y: no-repeat;
    background-position-y: bottom;
    height: 40px;
    img, video {
      max-height: 40px;
    }
    img.logo, video.logo {
      max-height: 35px;
    }
  }
}

.banner-top-margin {
  margin-top: 90px;
  @include media-breakpoint-down(xl) {
   margin-top: 40px;
  }
}