#news-list {
  .card {
    .card-text {

      h1, h2, h3, {
        font-size: 1.2em;
        font-weight: 900;
        color: $primary !important;

        & > * {
          color: $primary !important;
        }
      }

      h4, h5, h6, p {
        font-size: 1em;
        font-weight: 400;
        line-height: 1.5em;
        color: $midgrey !important;
      }
    }
  }
}

.btn-primary, .btn-warning {
  color: white;
  &:hover {
    color: white;
  }
}