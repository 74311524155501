.ck-content {
  a {
    text-decoration: revert;
    color: $info;
  }
  ul, ol {
    list-style: revert;
    padding: revert;
    margin-bottom: revert;
  }
  blockquote {
    border-left: 4px solid #f9c33e !important;
  }
}