#CookielawBanner {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: white;
  z-index: 1020;
  border-top: 4px solid $primary;
  border-bottom: 4px solid $primary;
  @include media-breakpoint-down(sm) {
    .cookie-text,
    .btn {
      font-size: 12px;
    }
  }
}

