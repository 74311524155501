$theme-colors: (
        "primary": #4bbc63,
        "secondary": #2c4e6e,
        "info": #6eb8ff,
        "dark": #1a1a1a,
        "danger": #842029,
        "warning": #dba617,
);
$enable-negative-margins: true;

@import "./bootstrap.scss";
@import "./fonts.scss";
@import "./variables.scss";
@import "cookielaw";
@import "admin";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Open Sans", sans-serif;
  color: $midgrey;
  min-height: 100vh;
  //display: flex;
  //flex-direction: column;
  &.modal-open {
    overflow: visible !important;
    padding-right: 0 !important;

    #main-menu {
      padding-right: 10px !important;
    }
  }
}

@import "layout";
@import "menu";

:target::before {
  content: "";
  display: block;
  height: 100px;
  margin: -100px 0 0;
}


.btn {
  border-radius: 8px;
}

.btn-third {
  background: #EDEFF8;
  color: #2c4e6e;
}

.btn-disabled {
  background: #dbdbdb;
  color: white;
  cursor: not-allowed;

  &:hover {
    background: #dbdbdb;
    color: white;
  }

  &:focus {
    box-shadow: none;
  }
}

ul.bullet-list, .partners-list ul {
  padding-bottom: 1em;

  li {
    margin-left: 1em;
  }

  li::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #f6c117; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }

}

#navbar-temp {
  //margin-top: 80px;
  //background: white;
  //z-index: 2;
  margin-top: 80px;
  z-index: 2;
  flex: 100% 1 0;
  margin: 0 -10px;
  padding-top: 10px;
  box-shadow: inset 0 1rem 1rem -0.75rem rgba(0, 0, 0, 0.2) !important;
  background: #fff;

  & .greeting {
    //padding: 0.5rem 0 0;
    //@include media-breakpoint-up(md) {
    //  padding: 0.5rem 1rem;
    //}
  }

  &.banner-top-margin {
    margin-top: 170px;
    @include media-breakpoint-down(xl) {
      margin-top: 115px;
    }

  }

  & .navbar-nav {
    & .nav-item {
      margin-right: 0;
    }
  }
}

#explanations {
  .container {
    background-image: url("/vendors/images/backgrounds-sections/blue.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
  }

  .carousel-inner {
    * {
      transition-delay: 0s !important;
      animation-delay: 0s !important;
    }

    @include media-breakpoint-up(lg) {
      height: 500px;
      width: 915px;
    }
  }

  .carousel-indicators {
    position: relative;

    & [data-bs-target] {
      text-indent: 0;
    }

    li {
      min-width: 60px;
      font-size: 25px;
      height: 70px;
      color: #6eb8ff;
      @include media-breakpoint-up(lg) {
        min-width: 200px;
        height: 170px;
        font-size: 100px;
      }

      &.active {
        background-image: url("/vendors/images/button-step.png");
        background-size: contain;
        background-repeat: no-repeat;
        color: white;
      }
    }
  }
}

#banner {
  .yellow-background-image-bg:before {
    content: "";
    display: block;
    position: absolute;
    background-image: url("/vendors/images/chameleon/cameleon-pub.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 160px;
    height: 150px;
    right: 5px;
    bottom: 50px;
    @include media-breakpoint-up(lg) {
      right: -100px;
      bottom: 0;

    }
  }
}

.mb-160 {
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }
}

#video-area {
  background-image: url("/vendors/images/dots-home-right.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

#last-preparations {
  h3.last-preparation-title {
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      position: relative;
      margin: 0 5px;
      background-image: url("/vendors/images/chameleon/chameleon-title-home.svg");
      background-repeat: no-repeat;
      width: 90px;
      height: 125px;
      bottom: -20px;
      //@include media-breakpoint-up(lg) {
      //left: 25%;
      //position: absolute;
      //}
    }
  }

  .btn-primary {
    @include media-breakpoint-down(md) {
      margin-top: 2em;
    }
  }
}

#last-news {
  padding-bottom: 160px;
  padding-top: 60px;
  @include media-breakpoint-down(md) {
    padding-bottom: 0;
  }

  h3 {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      background-image: url("/vendors/images/chameleon/cameleon-recherche.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 100px;
      height: 200px;
      left: -100px;
      bottom: -200px;
    }
  }

  .card {
    background-color: transparent;
    border: none;
    //margin-right: 45px;
    @include media-breakpoint-down(md) {
      background-color: #f5f6fa;
      border-radius: 10px;
      margin-bottom: 2em;
    }

    &:last-of-type {
      margin-right: 0;
    }

    .card-img-top {
      height: 190px;
      object-fit: cover;
      @include media-breakpoint-down(md) {
        height: 300px;
      }
    }


    span,
    p {
      font-size: 15px;
    }
  }
}

.greybackground {
  background-image: url("/vendors/images/grey-1.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.greybackground-left {
  background-image: url("/vendors/images/grey-1.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;

  p {
    font-size: 18px;
  }

  .chameleon-effect {

    &::before {
      content: url("/vendors/images/bullet-list-categories.png");
      margin-right: 5px;
      opacity: 0;
      transition: 0.4s;
      margin-left: -25px;
    }

    &:hover::before {
      opacity: 1;
      margin-left: 5px;
    }
  }
}

.grey-container {
  background: #f5f6fa;

  &.round {
    border-radius: 50px;
  }

  @include media-breakpoint-up(lg) {
    margin-left: -3rem;
    &.round {
      border-radius: 250px;
    }
  }
}

.alternate-color {
  h5.titlefont {
    color: #707070;
  }

  &:nth-of-type(2n) {
    h5.titlefont {
      color: $secondary;
    }
  }
}

.alternate-color-border-wrapper {
  .alternate-color-border:nth-of-type(2n) div {
    border-color: $primary !important;
  }
}

#newsletter {
  background-image: url("/vendors/images/backgrounds-sections/grey-1.svg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;

  .chameleon-pic {
    order: 1;
    max-height: 150px;
  }

  @include media-breakpoint-up(lg) {
    .chameleon-pic {
      margin-right: -70px;
      margin-left: 20px;
      order: 2;
    }
  }
}

#contact-form {
  h3 {
    position: relative;

    &::before {
      content: "";
      display: block;

      background-image: url("/vendors/images/chameleon/mascotte-contact.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 300px;
      height: 150px;

      bottom: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    h3:before {
      position: absolute;
      left: 60%;
    }
    .form-wrapper {
      padding-bottom: 50px;
    }
    .card {
      width: 75%;
    }
  }

  label {
    font-family: $titlefont;
    font-size: 20px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-control {
    border: 1px solid #707070;
    border-radius: 8px;
    padding: 15px;
  }
}

.connexion-form {
  .form-wrapper {
    padding-top: 90px;
    background-image: url("/vendors/images/backgrounds-sections/blue-connexion.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @include media-breakpoint-up(lg) {
      padding-top: 170px;
    }

    .form-bottom {
      text-align: center;

      input {
        width: 100%;
      }

      a {
        color: $lightblue;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  label {
    font-family: $titlefont;
    font-size: 20px;
    display: block;
  }

  .form-group {
    margin-bottom: 15px;

    input {
      border: 1px solid #707070;
      border-radius: 8px;
      padding: 15px;
      width: 100%;
    }
  }

  .form-control {
    border: 1px solid #707070;
    border-radius: 8px;
    padding: 15px;
  }

  .btn {
    font-size: 24px;
  }
}

.share-tabs {
  margin-top: 110px;

  .wrapper {
    padding-bottom: 70px;
    margin-top: 80px;
    margin-bottom: 15px;
    background-image: url("/vendors/images/backgrounds-sections/yellow-lg.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;

    .nav-link {
      background: $lightgrey;
      color: $secondary;
      //margin-right: 10px;
      font-weight: 600;
      border-radius: 8px;

      &:last-child {
        margin: 0;
      }

      &.active {
        background: $nav-tabs-link-active-bg;
        color: $nav-tabs-link-active-color;
      }
    }

    .tab-pane {
      margin-top: 50px;
      text-align: center;
      min-height: 25vh;

      .text-left {
        text-align: left;

        label {
          font-size: 1rem;
        }
      }
    }

    .consigne {
      min-height: fit-content;
      background: url("/vendors/images/backgrounds-sections/bg-tabs-footer.svg") no-repeat 0 100%;
      margin: 20px -48px -16px -48px;
      padding: 20px 50px;

      img {
        //height: 100%;
        //width: auto;
        display: block;
        width: 100%;
        height: auto;
      }

      .bulle {
        background-color: white;
        border-radius: 8px;
        padding: 15px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.125);
      }
    }

    #pills-explication {
      img {
        //margin-bottom: -1rem;
      }
    }
  }

  label {
    font-family: $titlefont;
    font-size: 20px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-control {
    //border: 1px solid #707070;
    border-radius: 8px;
    padding: 15px;
  }
}

#profile {
  .wrapper {
    background-image: url("/vendors/images/backgrounds-sections/blue-lg.png");
  }

  .button-third {
    background: #edeff8;
    color: #2c4e6e;
    font-weight: 600;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    border: none;
  }
}

#school-help {
  background-color: #f5f6fa;
  margin: 100px 0;

  &::before {
    background-image: url("/vendors/images/top-white.svg");
    margin-top: -10px;
    background-position: center top;
    position: relative;
  }

  &::after {
    background-image: url("/vendors/images/bottom-white.svg");
    margin-bottom: -1px;
    background-position: center bottom;
    position: relative;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    background-repeat: no-repeat;
    height: 110px;
  }

  .container-fluid {
    min-height: 200px;
  }
}

#partners {

  li {
    margin: 10px;
    width: 40%;
  }

  img {
    display: block;
    width: 100%;
  }

  h2 {
    position: relative;

    &::before {
      content: "";
      display: block;
      background-image: url("/vendors/images/chameleon/mascotte-partenaires.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 150px;
      height: 150px;
      margin: auto;
      left: 20%;
      bottom: -50px;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: 40px;
    li {
      width: 180px;
      margin: 15px;
    }
    img {
      width: 80%;
    }
    h2:before {
      position: absolute;
      left: 5%;
      height: 200px;
      margin: unset;
    }
  }
}

#share-actions {
  background-color: white;
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 5;
  transform: translateY(-25%);

  ul {
    li {
      margin-bottom: 15px;
    }

    li:last-of-type {
      margin-bottom: unset;
    }
  }
}

.btn-circle {
  border-radius: 30px;
  width: 40px;
  height: 40px;
  margin: 0 1em;

  img {
    width: 10px;
  }
}

//preparation
#preparation-search {
  //stylistation form recherch
  .form-check-label,
  .form-control,
  .form-control::placeholder,
  .vs__dropdown-toggle,
  .vs__selected {
    font-family: $titlefont;
    color: $secondary;
    text-transform: uppercase;
  }

  .v-select {
    background: white;
    border-radius: 8px;
  }

  .vs__dropdown-toggle,
  .form-control {
    border: 1px #e4e4e4 solid;
    border-radius: 8px;
  }

  .vs__selected-options {
    padding: 10px;
    flex-wrap: nowrap;
  }

  .form-control {
    padding: 14px;
  }

  .select-container {
    padding-right: 80px;
  }

  .vs__actions {
    position: absolute;
    z-index: 2;
    height: 50px;
    right: -60px;
    width: 50px;
    display: flex;
    justify-content: center;
    background: $primary;
    border-radius: 50px;

    .vs__open-indicator {
      fill: white;
      position: relative;
    }

    .vs__clear {
      position: absolute;
      left: -35px;
    }
  }

  .form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
  }

  .vs--disabled {
    opacity: 0.6;
  }

  .vs__search,
  .vs__search:focus,
  .select-container,
  .form-control {
    color: #2c4e6e;
  }
}

//#searchbar {
//  .vs__search,
//  .vs__search:focus,
//  .select-container,
//  .form-control {
//    color: #2c4e6e;
//  }
//}
$checkbox-size: 26px;
$checkbox-right-margin: 15px;
.multiselect {
  .round-select {
    position: absolute;
    z-index: 2;
    height: 50px;
    right: -60px;
    width: 50px;
    display: flex;
    justify-content: center;
    background: $primary;
    border-radius: 50px;
    align-items: center;
    transition: transform .1s linear;
  }

  .multiselect__tags {
    padding: 12px 0 4px 16px;
    overflow: hidden;

    &-wrap {
      //white-space: nowrap;
    }
  }

  &.multiselect--active .round-select {
    transform: rotate(180deg);
  }

  .multiselect__placeholder {
    color: #2c4e6e;
    font-family: "Gotham Rounded", sans-serif;
    font-weight: 400;
    font-size: 16px;
  }

  .multiselect__option {
    &::after {
      background-color: white;
      color: black;
      content: " \2610";
      position: absolute;
      top: 0;
      bottom: 0;
      right: $checkbox-right-margin;
      width: 32px;
      font-size: $checkbox-size;
      //height: 32px;
    }
  }

  .multiselect__option--highlight {
    background-color: white;
    color: black;
  }

  .multiselect__option--selected {
    color: black;
    background-color: white;

    &.multiselect__option--highlight, &.multiselect__option--highlight:hover {
      background-color: white;
      color: black;

      &::after {
        background-color: white;
        color: black;
        content: " \2611";
      }
    }

    &::after {
      background-color: white;
      color: black;
      content: " \2611";
      position: absolute;
      top: 0;
      bottom: 0;
      right: $checkbox-right-margin;
      width: 32px;
      font-size: $checkbox-size;
      //height: 32px;
    }
  }
}

.result-preparation, .result-preparation-home {
  .card {
    padding-left: 0;
    padding-right: 0;

    &::before {
      content: "";
      width: 100%;
      position: absolute;
      display: block;
      background-color: #f9c33e;
      border-radius: 10px;
      transition: all $folding-duration ease-in-out;
    }

    &.rejected::before {
      background-color: #e10101;
    }

    &.pending::before {
      background-color: #cc8206;
    }

    .arrow-right {
      img {
        width: 30px;
        height: 30px;
        margin: 10px 15px;
        transform: rotateZ(0deg);
        transition: transform $folding-duration;
        @include media-breakpoint-down(md) {
          margin-left: 35px;
        }
      }
    }

    &.folded::before {
      height: 5px;
      top: -5px;
    }

    &.unfolded::before {
      height: 15px;
      top: -15px;
    }
  }

  .prepa-counters {
    width: 115px;
    font-size: 1.2em;
    background-color: #f5f6fa;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    & > div {
      margin: 0 7px;
    }

    .fas {
      padding-right: 1px;
    }
  }

  @include media-breakpoint-up(lg) {
    h5 {
      padding: 1rem 0 0 3rem;
      //font-size: 40px;
    }
  }

  h5 {
    transition: font-size $folding-duration ease-in-out;
    padding: 0.65rem 1.5rem;
    margin-bottom: 0;
  }

  .unfolded {
    h5 {
      font-size: 30px;
    }


    .arrow-right {
      img {
        transform: rotateZ(-180deg);
      }
    }

    .folding-area {
      max-height: 3000vh;
      opacity: 1;
      transition: all $folding-duration ease-in-out;
    }
  }

  .folded {
    .card-title {
      margin-bottom: 0;
    }

    h5 {
      font-size: 24px;
    }


    .card-body {
      padding-bottom: 0;
      padding-top: 0;
      overflow: hidden;
    }

    .folding-area {
      max-height: 0;
      opacity: 0;
      transition: all $folding-duration ease-in-out;
    }
  }

  div > img {
    height: 290px;
    width: 290px;
    margin-right: 0;
    background-color: white;
    display: block;
    object-fit: cover;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .result-metadata {
    display: flex;
    justify-content: space-between;
  }


  .card-text {
    line-height: 1.5;
    overflow-wrap: break-word;

    img {
      margin-left: 10px;
      max-width: 150px;
      height: auto;
      float: left;
    }
  }
}

.result-preparation-home {
  h5 {
    padding-left: 0;
  }
}

//partners page
.partners-list {
  .bg-blue {
    background: url("/vendors/images/tache_partenaires.svg") no-repeat top left;
    background-size: 100%;
  }

  .bg-yellow {
    background: url("/vendors/images/tache_partenaires2.svg") no-repeat bottom;
  }

  .container > div:nth-child(odd) {
    .card-title {
      color: $lightblue;
    }
  }

  .card-text strong {
    color: $primary;
  }

  a {
    color: $info;
    text-decoration: underline;
  }
}

#about {
  .chameleon-pic {
    position: absolute;
    height: 70px;
    top: -64px;
    left: 180px;
    z-index: 2;
  }

  .yellow-background-image-bg {
    background-position: top;
  }

  .card {
    display: flex;
    flex-direction: row;
    margin: auto;

    .member-portrait {
      //object-fit: cover;
      align-self: start;
      margin: 1rem;
      max-width: 350px;
      max-height: 350px;
    }

    .card-title {
      font-family: $titlefont;
      color: $primary;
    }

    &:nth-of-type(odd) .card-title {
      color: $secondary;
    }

    &:nth-of-type(4n):after {
      background: url("/vendors/images/dots-home-right.png") no-repeat bottom;
      content: "";
      width: 100vw;
      height: 485px;
      left: -50%;
      position: absolute;
      z-index: -1;
      top: -250px;
    }

    @include media-breakpoint-up(lg) {
      .member-portrait {
        margin: 1rem 0 1rem 1rem;
      }
      img {
        width: 300px;
      }

    }

    img {
      width: 100%;
      object-fit: contain;
      margin-top: 0;
    }

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }

}

//preparation single
#preparation-single {

  .wrapper {
    border: solid lightgray 1px;
    z-index: 2;
    margin-top: 40px;

    &:after {
      content: "";
      width: 250px;
      height: 250px;
      position: absolute;
      right: -120px;
      top: -25px;
      background: url("/vendors/images/chameleon/hidden_chameleon.svg") no-repeat bottom;
    }
  }

  img {
    object-fit: contain;
  }

  .preparation-wrapper {
    margin-left: 10px;
    margin-bottom: 10px;
    border: solid lightgray 1px;

    .tag-list {
      margin-top: 5px;
      color: $secondary;
      font-weight: 900;
    }
  }
}

.info-list {
  font-weight: 600;
  color: darkgray;

  .date {
    color: $primary;
  }
}

.tag-list {
  display: flex;
  justify-content: flex-start;

  li, button, a {
    margin-bottom: 7px;
    margin-right: 7px;
    font-size: 14px;
    line-height: 16px;
  }

  flex-wrap: wrap;
}

.library-tab {
  transition: all 0.2s ease-in;

  &.openTab {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.library {
  position: relative;
  background: white;
  overflow: hidden;
  height: 0;
  //max-height: 550px;
  margin-top: -1.5rem;
  margin-left: 0.5rem;
  transition: height 0.5s ease-in-out;
  border: 1px solid transparent;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .close-button {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    border-top-left-radius: 5px;
    border: none;
    box-shadow: none;
  }

  &.show {
    height: auto;
    border: 1px solid var(--ck-color-base-border);
  }

  img {
    margin: 5px;
    border-radius: 10px;
    max-width: 100px;
    border: 2px solid transparent;

    &:hover {
      border: 3px solid $primary;
    }

    &.selected {
      border: 1px solid $primary;
    }
  }
}

.link-wrapper {
  width: 100%;
  background: white;
  display: flex;
  flex-wrap: nowrap;
  padding: 15px;
  justify-content: flex-end;

  a {
    white-space: nowrap;
  }

  & > :not(:last-child) {
    margin-right: 15px;
  }

  @include media-breakpoint-down(md) {
    display: block;
    padding: 0;

    & > div {
      display: inline-block;
    }
    & > :not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

.social-shares-admin {
  display: inline-block;
  font-size: 1.5em;
  line-height: 20px;

  .fb-share {
    color: #4267B2;
  }
}

.social-shares {
  font-size: 2em;
  line-height: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;

  i {
    padding: 0 5px;
  }

  .mail-share {
    color: $primary;
  }

  .linkedin-share {
    color: #2867B2;
  }

  .fb-share {
    color: #4267B2;
  }

  .twitter-share {
    color: #1DA1F2;
  }
}

// news
.news-pinned {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 32px;
  height: 32px;
}

#news-list {
  .news-pinned {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 32px;
    height: 32px;
  }

  .category-list {
    background: url("/vendors/images/category-list-bg.svg");
    margin-top: 80px;

    .top-picture {
      margin-top: -180px;

      img {
        width: 110% !important;
        height: auto;
        margin-left: -10px;
        margin-right: unset;
      }

      @include media-breakpoint-up(lg) {
        margin-top: -180px;
        img {
          width: auto;
          height: auto;
          background-color: transparent;
          margin-left: auto;
          margin-right: 0;
        }
      }
    }

    .news-category-item {
      a {
        font-size: 1.1em;
      }

      &::before {
        content: url("/vendors/images/bullet-list-categories.png");
        margin-right: 5px;
        opacity: 0;
        transition: 0.4s;
        margin-left: -15px;

      }

      &:hover::before {
        opacity: 1;
        margin-left: 5px;
      }
    }
  }
}

.editable-content-wrapper {
  overflow-wrap: break-word;

  img {
    max-width: 100%;
  }

  .tag-list {
    a {
      text-decoration: none;
      font-size: 14px;
    }
  }

  .alignright {
    float: right;
    margin-left: 1.25rem;
    margin-top: 1.25rem;
  }

  .alignleft {
    float: left;
    margin-right: 1.25rem;
    margin-top: 1.25rem;
  }

  .aligncenter {
    width: 100%;
    object-fit: contain;
    margin-bottom: 10px;
  }

  ol {
    margin: 1em 1rem;
    padding: 0;

    li {
      margin-top: 1em;
      margin-left: 2.5rem;
    }
  }

  ul:not(.tag-list) {
    margin: 1em 1rem;

    li {
      position: relative;
      list-style: none;
      margin-top: 1em;
      margin-left: 2.5rem;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -1.2em;
        top: 0.65em;
        width: 5px;
        height: 5px;
        background: #f9c33e;
        border-radius: 100%;
      }

      .fa-star {
        opacity: 0;
      }
    }
  }

  blockquote {
    margin: 1.2em 0;
    border-left: 4px solid #f9c33e;
    padding-left: 20px;
    clear: both;
    font-style: italic;
    font-weight: 400;
    color: #585f65;

    p {
      font-size: 1.1em;
      padding: 0;
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    margin: 1em 0 0.75em;
    font-family: $titlefont;
  }

  h3 {
    color: #6eb8ff;
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0.5em 0;
    font-family: $titlefont;
  }

  h4 {
    font-size: 1rem;
    font-weight: 600;
  }

  a {
    color: $info;
    text-decoration: underline;
  }

  .external-resources {
    @include media-breakpoint-down(lg) {
      li {
        margin-left: 0 !important;

        &:before {
          content: none !important;
        }
      }


    }

    a {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;

      @include media-breakpoint-down(lg) {
        line-break: anywhere;
      }
    }
  }

  p {
    font-size: 1rem;
    padding: 0.25em 0;

  }

  .old-content-workaround {
    p:empty {
      display: none;
    }

    br {
      display: none;
    }
  }

  .elementor-widget-wrap {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}


//tutoring

.tutoring-nav {
  .sticky-top {
    &::after {
      content: "";
      display: none;
      position: absolute;
      background-image: url("/vendors/images/chameleon/chameleon-tutoring.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 150px;
      height: 200px;
      bottom: -200px;
      right: 0;
    }

    @include media-breakpoint-up(lg) {
      &::after {
        display: block;
      }
    }
  }
}

#tutoring-content {
  #pills-tutoring img {
    max-width: 50%;
    padding-left: 20px;
    max-height: 180px;
  }

  #pills-inscription img {
    max-height: 250px;
    @include media-breakpoint-down(sm) {
      width: 160px;
      margin-right: -40px;
      margin-top: -50px;
    }
  }

  #pills-tab {
    .btn {
      width: 100%;

      &.btn-third.active {
        background-color: #2c4e6e;
        border-color: #2c4e6e;
        color: #ffffff;

        &.focus {
          border-color: #4bbc63;
        }
      }
    }
  }
}

#pills-tab-create .nav-link {
  white-space: pre;
  margin-bottom: 10px;
  @include media-breakpoint-up(xl) {
    margin-bottom: 0;
  }
}

// legal
#legal {

  .wrapper {
    border: solid lightgray 1px;
    z-index: 2;
    margin-top: 200px;

    p {
      font-size: 1em;
    }

  }

  h2 {
    font-size: 1.6rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  img {
    object-fit: contain;
  }

  .preparation-wrapper {
    margin-left: 10px;
    margin-bottom: 10px;
    border: solid lightgray 1px;

    .tag-list {
      margin-top: 5px;
      color: $secondary;
      font-weight: 900;
    }
  }
}

.text-red {
  color: #fd702e;
}

.bg-danger {
  background: $danger;
}

.yellow-background-image-bg {
  background-image: url("/vendors/images/green-dots-bg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

//

.header {
  background-position: top right;
  background-repeat: no-repeat;
  padding-top: 90px;
  //padding-top: 140px;
  background-size: auto 80%;

  @include media-breakpoint-down(xl) {
    background-size: auto 60%;
  }
  @include media-breakpoint-down(lg) {
    background-size: 0;
  }

  h1 {
    position: relative;
    display: block;

    &.icon-left {
      padding-left: 90px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      background-image: url("/vendors/images/chameleon/chameleon-title-home.svg");
      background-repeat: no-repeat;
      width: 90px;
      height: 125px;
      left: -10px;
      //top: -25px;
      top: auto;
      bottom: -30px;
      background-size: contain;
    }
  }


  &.bg-yellow {
    background-image: url("/vendors/images/headers/header-yellow.png");
  }

  &.bg-halloween {
    background-image: url("/vendors/images/hall-fond.png");
  }

  &.bg-blue {
    background-image: url("/vendors/images/headers/header-blue.png");
  }

  &#header-home {
    #logo {
      position: relative;
      display: block;

      &.icon-left {
        padding-left: 90px;
      }

      img {
        max-width: 420px;
        margin-top: 25px;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }

      &::before {
        background-image: url("/vendors/images/chameleon/chameleon-title-home.svg");
        display: block;
        position: absolute;
        background-repeat: no-repeat;
        width: 90px;
        height: 125px;
        left: -10px;
        top: -25px;
        content: "";
      }
    }
  }

  &#header-about {
    h1:before {
      background-image: url("/vendors/images/chameleon/Mascotte5.png");
      //width: 200px;
      //height: 300px;
      //left: -140px;
      //top: -70px;
      width: 100px;
      height: 130px;
      left: -10px;
      top: -50px;

    }

    @include media-breakpoint-up(sm) {
      h1 {
        padding-left: 0px;
      }
      h1:before {
        width: 200px;
        height: 300px;
        left: -130px;
        top: -140px;
      }
    }
    @include media-breakpoint-up(md) {
      h1 {
        padding-left: 70px;
      }
      h1:before {
        width: 200px;
        height: 300px;
        left: -50px;
        top: -140px;
      }
    }
    @include media-breakpoint-up(lg) {
      h1 {
        padding-left: 0px;
      }
      h1:before {
        left: -140px;
        top: -70px;
      }
    }
  }

  &#user-preparation-list-header img {
    bottom: -5vh;
  }

  &#preparation-single-header {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 0;
    height: 350px;
  }

  &#news-header {
    @include media-breakpoint-up(lg) {
      margin-bottom: 8em;
    }

    img {
      width: 100%;
    }
  }
}

footer {
  background-image: url("/vendors/images/footer.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: calc(100vw * 0.053);


  .container-fluid {
    background-color: $secondary;
    margin-top: -2px;

    &.footer__legal {
      background-color: darken(#879aac, 20);
    }
  }

  .logo {
    max-width: 150px;
  }

  .col-3:last-child {
    margin-right: 0;
    margin-left: auto;
  }

  .support {
    background-color: #879aac;
    padding: 10px;
    //border-radius: 30px;
    color: #fff;
    margin: 20px 0 0 0;
    text-align: center;

    p {
      font-size: 1rem;
      margin-bottom: 0;
    }

    img {
      max-height: 50px;
      margin: 10px 3rem;
    }
  }


  .chameleon-effect {
    a {
      font-size: 1.1em;
    }

    &::before {
      content: url("/vendors/images/bullet-list-categories.png");
      margin-right: 5px;
      opacity: 0;
      transition: 0.4s;
      margin-left: -25px;
    }

    &:hover::before {
      opacity: 1;
      margin-left: 5px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .w-lg-50 {
    width: 75%;
  }
  .w-lg-75 {
    width: 75%;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
}

.btn-sm {
  background-color: #f5f6fa;
}

.pop-up-box {
  position: fixed;
  background-image: url("/vendors/images/pop-up-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  bottom: 0;
  display: flex;
  z-index: 1021;
  width: 340px;
  height: 230px;
  padding: 55px 15px 5px 50px;
  flex-direction: column;

  transform: scaleX(-1);

  img {
    max-width: 100%;
  }

  .pop-up-body {
    width: 85%;
    height: 81.5%;
    margin: 3px -8px auto auto;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.125);
    display: flex;
    flex-direction: column;
    justify-content: center;

    transform: scaleX(-1);

    a, img {
      max-height: 100%;
      max-width: 100%;
      text-align: center;
    }
  }

  &.hidden {
    opacity: 0;
    z-index: -1;
  }

  .close-button {
    position: absolute;
    left: 25px;
    top: 25px;
    color: white;
    background-color: #fe6e0c;

    i {
      padding: 0 8px;
    }
  }

  &#partners-modal .titlefont {
    margin-left: 20px;
    transform: scaleX(-1);
  }


  @include media-breakpoint-down(md) {
    width: 340px * .7;
    height: 230px * .7;
    padding: 39px 15px 2px 38px;

    left: 0;
    bottom: 0;


    .close-button {
      right: 175px;
      top: 0;

      i {
        padding: 7px 15px;
      }
    }
    .pop-up-body {
      width: 85%;
      height: 81.5%;
      margin: 0 -8px auto auto;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.125);
      display: flex;
      flex-direction: column;
      justify-content: center;

      a, img {
        max-height: 100%;
        max-width: 100%;
        text-align: center;
      }
    }

    &#partners-modal .titlefont {
      margin-left: 30px;
      font-size: .6em;
    }

  }

}

.modal-dialog {
  label {
    text-transform: unset;
    font-weight: 600;
    font-family: $titlefont;
  }

  .form-group {
    margin-bottom: 10px;

    // warning: what is the purpose of this style
    //&:nth-of-type(2n) label {
    //  color: unset;
    //}
  }

  .form-check, .form-group {
    label {
      color: $secondary;
    }
  }

  .close-button {
    background-color: $lightgrey;

  }

  p {
    font-size: unset;
    margin-bottom: 5px;
  }

  a {
    margin-top: 5px;
  }

}

.italic {
  font-style: italic;
}

.mandarory-form {
  background-color: $lightgrey;
  padding: 10px;
  display: block;
  font-style: italic;
  width: 160px;
  margin: auto 0 auto auto;
  border-radius: 20px;
  font-size: 14px;
}

// warning: why resets style at end of stylesheet ?

//reset style link
a {
  text-decoration: none;
  color: inherit;

  &:hover {
    color: inherit;
  }
}

//reset style ul
ul {
  padding: unset;
  list-style: none;
  margin-bottom: unset;
}

//partners page
.partners-logo {
  max-width: 250px;
  max-height: 250px;
}

#pills-school {
  .school-item {
    padding: 15px;
    width: 100%;

    @include media-breakpoint-up(xl) {
      width: 46%;
      margin: 7px;
    }


    &.active {
      background-color: #fce19f;
      border: #f9c33e;
      border-radius: 8px;
    }
  }
}

.related-tags {
  font-size: .8em;
}

.alert a {
  //font-weight: 900;
  text-decoration: underline;
}

@import "overrides";
@import "topbanner";
@import "joboffer";
@import "summernote";
@import "ckeditor";

#btn-back-to-top {
  position: fixed;
  bottom: 15px;
  left: 88%;
  transform: translateX(-50%);
  display: none;
  z-index: 1021;
}

.multiselect__option--whitespace-wrap {
  .multiselect__option {
    white-space: normal;
  }
}

.notfound {
  max-width: 660px;
  width: 100%;
  padding-left: 160px;
  line-height: 1.1;
  @include media-breakpoint-down(md) {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 110px;
  }

  & .notfound-404 {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 140px;
    height: 140px;
    background-image: url("/vendors/images/chameleon/mascotte-partenaires.svg");
    background-size: cover;
    @include media-breakpoint-down(md) {
      width: 110px;
      height: 110px;
    }
  }

  & .notfound-404:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(2.4);
    border-radius: 50%;
    background-color: #f2f5f8;
    z-index: -1;
  }

  & h1 {
    font-size: 65px;
    font-weight: 700;
    color: #151723;
  }

  & h2 {
    font-size: 21px;
    font-weight: 400;
    color: #151723;
  }

  & p {
    color: #999fa5;
    font-weight: 400;
  }

  & a {
    display: inline-block;
    font-weight: 700;
    border-radius: 40px;
    text-decoration: none;
    color: #388dbc;
  }
}
