//declaration des variables
$primary: #4bbc63;
$secondary:#2c4e6e;
$lightblue:#6eb8ff;
$midgrey:#707070;
$lightgrey:#EDEFF8;
$dark:#1A1A1A;
$nav-tabs-link-active-color:#ffffff;
$nav-tabs-link-active-bg:#4bbc63;
$nav-pills-link-active-color:#ffffff;
$nav-pills-link-active-bg:#4bbc63;

$nav-pills-border-radius:8px;
$folding-duration: .4s;

.midgrey-color{
  color: $midgrey;
}
.midgrey-bg{
  background-color: $midgrey;
}
