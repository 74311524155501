//handle resizing of main menu
body.menu-small {
  #main-menu {
    padding: 0 10px;
    //display: none;
    #logo-img { height: 25px;}
  }
}

#main-menu {
  background-color: white;
  transition: padding 0.4s; /* Adds a transition effect when the padding is decreased */
  padding: 10px;
  right: auto;
  max-width: 100vw;

  .dropdown-item:hover{ background-color: #fff;}

  #logo {
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 0;
    width: 100px;
  }
  #logo-img { transition: 0.4s;}
  ul li {
    margin-right: 6px;
    font-size: 16px;
    &:last-of-type {margin-right: unset;}
  }
  .col-2 {
    margin-right: 5px;
    a {font-size: 10px;}
  }

  //@include media-breakpoint-up(xxl) {
  //  ul li { font-size: 20px;}
  //}

  @include media-breakpoint-up(lg) {
    #logo{ min-width: 200px;}
    #logo-img { height: 52px;}
    .col-2 {
      margin-right: unset;
      a { font-size: unset;}
    }
  }

  @include media-breakpoint-down(lg) {
    .navbar-nav .dropdown-menu { margin-left: 10px;}
    .dropdown-item.nav-link { padding-left: 20px;}
    ul li { font-size: 16px;}
  }
}
